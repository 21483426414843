import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

const Logo = () => {
  return (
    <Box>
      <Link to="/">
          {/*<Box component="img" src="/custom_login/static/icon_logo.png" alt="logo" width={100} />*/}
          <Box component="img" src="https://romon-group.com/wp-content/uploads/2020/06/ROMON-Logo-vec-3-768x768.png" alt="logo" width={100} />
      </Link>
    </Box>
  );
};

export default Logo;
