import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios"


import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Alert, Fade
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const SigninForm = ({ setAuth }) => {

  const [result, setResult] = useState( {response: null, severity: null, message: null} );

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Provide a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: LoginSchema,
    onSubmit: ( values, actions ) => {
      console.log("submitting...");
      axios
          .post('/api/auth/login', values )
          .then(response => {
            console.log('Login response data', response.data);

              setResult({response: response.data.code, severity: response.data.severity, message: response.data.message});

              if( response.data.code === 202 ){
                  setTimeout(()=>{
                      window.location.href = response.data.redirect;
                  }, 1300 );
              }
              else {
                  actions.setSubmitting( false );
              }

              // switch (response.data.code){
              //   case 202:
              //       break;
              //   case 406:
              //       break;
              //   }
              //
              //
              //   if( response.data.code === 202 ){
              //       // setResult({response: 202, severity: 'success', message: 'Authentication successfully, please wait.'});
              //       setResult({response: 202, severity: response.data.severity, message: response.data.message});
              //   }
          }).catch(error => {
                actions.setSubmitting( false );
                setResult({response: 404, severity: 'error', message: error.response.data.error});
                console.log(error.response.data);
          }).finally(() => {
              console.log('Finally...');
          })
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <React.Fragment>
      { result.response &&
        <Fade in={true}>
          <Alert sx={{mb:3}} severity={result.severity}>{ result.message }</Alert>
        </Fade>
      }

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box
              component={motion.div}
              animate={{
                transition: {
                  staggerChildren: 0.55,
                },
              }}
          >
            <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                }}
                component={motion.div}
                initial={{ opacity: 0, y: 40 }}
                animate={animate}
            >
              <TextField
                  //variant={'filled'}
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email Address"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
              />

              <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                              onClick={() => setShowPassword((prev) => !prev)}
                          >
                            {showPassword ? (
                                <Icon icon="eva:eye-fill" />
                            ) : (
                                <Icon icon="eva:eye-off-fill" />
                            )}
                          </IconButton>
                        </InputAdornment>
                    ),
                  }}
              />

            </Box>

            <Box
                component={motion.div}
                initial={{ opacity: 0, y: 20 }}
                animate={animate}
            >
              <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ my: 2 }}
              >
                <FormControlLabel
                    control={
                      <Checkbox
                          {...getFieldProps("remember")}
                          checked={values._remember_me}
                          name={'_remember_me'}
                      />
                    }
                    label="Remember me"
                />

                <Link
                    component={RouterLink}
                    variant="subtitle2"
                    to="/password-forgot"
                    underline="hover"
                >
                  Forgot password?
                </Link>
              </Stack>

              <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
              >
                {isSubmitting ? "loading..." : "Login"}
              </LoadingButton>

            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </React.Fragment>
  );
};

export default SigninForm;
