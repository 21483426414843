import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Typography, Link, Box } from "@mui/material";
import styled from "@emotion/styled";
import Logo from "../components/Logo";
import { motion } from "framer-motion";
import ForgotPassword from "../components/ForgotPassword";
// import Divider from "@mui/material/Divider";

//////////////////////////////////
const RootStyle = styled("div")({
  background: "rgb(249, 250, 251)",
  height: "100vh",
  display: "grid",
  placeItems: "center",
});

const HeadingStyle = styled(Box)({
  textAlign: "center",
});

const ContentStyle = styled("div")({
  maxWidth: 480,
  padding: 25,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  background: "#fff",
  borderRadius: 24,
  border: '.5px solid #CCC'
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const Forgot = ({ setAuth }) => {
  return (
    <RootStyle>
      <Container maxWidth="xs" sx={{boxShadow:'2px 2px 10px #CCCCCC', borderRadius: 12, pl: '0!important', pr: '0!important'}} >
        <ContentStyle>
          <HeadingStyle component={motion.div} {...fadeInUp}>
            <Logo />
            <Typography variant={'overline'} component={motion.h2} sx={{ color: "text.secondary", mb: 5 }}>
              <Typography variant={'h6'}>
                Forgot your password?
              </Typography>
            </Typography>
          </HeadingStyle>

          <ForgotPassword setAuth={setAuth} />

          <Typography
            component={motion.p}
            {...fadeInUp}
            variant="body2"
            align="center"
            sx={{ mt: 3 }}
          >
            You already have an account please { " " }
            <Link variant="subtitle2" component={RouterLink} to="/login">
              Sign in
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Forgot;
