import React, {Component} from 'react';
import LoadingButton from "@mui/lab/LoadingButton";
import {styled} from "@mui/material";
import {SxProps} from '@mui/material/styles';

interface MyThemeComponentProps {
    color?: 'primary' | 'secondary';
    variant?: ButtonVariantTypes
}

const StyledLoadingButton = styled(LoadingButton, { shouldForwardProp: prop => true
})<{sx?: SxProps}>(({ theme, sx }) => ({
    "& .button-content": {
        display: 'flex',
        gap:'.2rem',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& *':{
            display: 'flex'
        }
    }
}));

type OnLoadCallbackArgs = <T>(ButtonView: ButtonView) => void;
type OnClickCallbackArgs = <T>(ButtonView: ButtonView, event?: React.MouseEvent) => void;
type ButtonVariantTypes = "text" | "contained" | "outlined";
type ButtonSizeTypes = 'small' | 'medium' | 'large';

interface Props {
    label: any
    disabled?: boolean,
    onClick?: OnClickCallbackArgs,
    onLoad?: OnLoadCallbackArgs
    variant?: ButtonVariantTypes
    size?: ButtonSizeTypes
    sx?: SxProps
}

interface State {
    disabled?: boolean,
    label: any,
    inProcess?: boolean
}

class ButtonView extends Component<Props, State> {

    private disabled: boolean;
    private label: any;
    private inProcess: any;

    constructor(props){
        super(props);

        this.state = {
            label: 'Button Label',
            disabled: false,
            inProcess: false
        };

        this.disabled = false;
        this.label = 'Button Label';
    }

    componentDidMount() {
        if( undefined !== this.props.onLoad && typeof this.props.onLoad === "function" ){
            this.props.onLoad( this );
        }
        if( this.props.disabled !== undefined ){
            this.setState({...this.state, disabled: this.props.disabled });
            // this.disabled = this.props.disabled;
        }
        if( this.props.label !== undefined ){
            this.setState({...this.state, label: this.props.label });
            // this.label = this.props.label;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.disabled !== this.props.disabled ){
            this.setState({...this.state, disabled: this.props.disabled });
            // this.disabled = this.props.disabled;
        }
        if( prevProps.label !== this.props.label ){
            this.setState({...this.state, label: this.props.label });
            // this.label = this.props.label;
        }
    }

    setInProcess = (process: boolean) : this => {
        this.setState({...this.state, inProcess: process });
        // this.inProcess = process;
        return this;
    };

    setDisabled = (disabled: boolean) : this => {
        this.setState({...this.state, disabled: disabled });
        // this.disabled = disabled;
        return this;
    };

    setLabel = (label: any) : this => {
        this.setState({...this.state, label: label });
        // this.label = label;
        return this;
    };

    render() {
        return (
            <StyledLoadingButton

                sx={this.props.sx}
                href={'#'}
                size={ this.props.size ? this.props.size : 'small'}
                onClick={ event => {
                    if( this.props.onClick !== undefined && typeof this.props.onClick === "function" ){
                        this.props.onClick( this, event );
                    }
                }}
                loading={ this.state.inProcess }
                disabled={ this.state.disabled }
                variant={ this.props.variant ? this.props.variant : 'contained' }
                
            >
                <div className='button-content'>
                    { Array.isArray( this.state.label ) ?
                        this.state.label.map( ( item, index ) => (
                            <div key={index}>{item}</div>
                        )):
                        this.state.label
                    }
                </div>
            </StyledLoadingButton>
        );
    }
}

export default ButtonView;