import React, {useRef, useState} from "react";
import {useFormik, FormikProvider, Form} from "formik";
import * as Yup from "yup";
import axios from "axios"


import {
    Box,
    TextField,
    Alert, Fade, InputAdornment, IconButton, Stack, FormControlLabel, Checkbox, Link, Button
} from "@mui/material";
import { motion } from "framer-motion";
import ButtonView from "../Materials/ButtonView"
import AISHTTPRequest from "../AISKit.tsx";
import Typography from "@mui/material/Typography"
import {Icon} from "@iconify/react";
import {Link as RouterLink} from "react-router-dom";
import {LoadingButton} from "@mui/lab";



let easing = [0.6, -0.05, 0.01, 0.99];
const animateInitialOut = { opacity: 0, y: 40, height: 0 };
const animateInitialOut2 = { opacity: 0, y: 20 };
const animateInitialOut3 = { opacity: 0, y: 80, height: 0 };

const animateBottomToUp = {
    opacity: 1,
    y: 0,
    height: 'auto',
    transition: {
        duration: 0.6,
        ease: easing,
        delay: 0.16,
    },
};

const animateUpToBottom = {
    opacity: 0,
    y: 40,
    height: 0,
    transition: {
        duration: 0.6,
        ease: easing,
        delay: 0.16,
    },
};

const ForgotPassword = ({ setAuth }) => {

    const [result, setResult] = useState( {response: null, severity: null, message: null} );
    const [email, setEmail] = useState( null );

    const LoginSchema = Yup.object().shape({
        reset_email: Yup.string()
            .email("Provide a valid email address")
            .required("Email is required")
    });

    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: LoginSchema,
        onSubmit: ( values, actions ) => {
            console.log("submitting...");
            axios
                .post('/api/auth/check-user', values )
                .then(response => {
                    console.log(response.data);
                    // if( response.data.code === 202 ){
                    //
                    //     setResult({response: 202, severity: 'success', message: 'Authentication successfully, please wait.'});
                    //
                    //     setTimeout(()=>{
                    //         window.location.href = response.data.redirect;
                    //     }, 1000 );
                    //
                    // }
                }).catch(error => {
                    actions.setSubmitting( false );
                    // setResult({response: 404, severity: 'error', message: error.response.data.error});
                    console.log(error.response.data);
            }).finally(() => {
                    console.log('Finally...');
            })
        },
    });

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } =
        formik;




    return (

        <React.Fragment>

            { result.response &&
            <Box
                component={motion.div}
                initial={animateInitialOut3}
                animate={animateBottomToUp}
            >
                <Alert
                    sx={{height: '100%'}} severity={result.severity}>{ result.message }
                </Alert>
            </Box>
            }

            <Box
                component={motion.div}
                animate={{
                    transition: {
                        staggerChildren: 0.55,
                    },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                    component={motion.div}
                    initial={animateInitialOut}
                    animate={ result.response ? animateUpToBottom : animateBottomToUp  }
                >

                    { !result.response &&
                        <Box component={motion.div} sx={{mb: 0}} >



                            <Typography component={motion.p} variant={'caption'}
                                sx={{pl: 2}}
                            >Change your password in three easy steps. This will help you to secure your password!</Typography>

                            <ol className="list-unstyled">
                                <li><span className="text-primary text-medium"></span>Enter your email address below.</li>
                                <li><span className="text-primary text-medium"></span>Our system will send you a temporary link</li>
                                <li><span className="text-primary text-medium"></span>Use the link to reset your password</li>
                            </ol>


                        </Box>
                    }


                    <TextField
                        fullWidth
                        inputProps={{
                            onBlur: event => {
                                setEmail(event.target.value);
                            }
                        }}
                        autoComplete={"email"}
                        type="email"
                        label="Email Address"
                        {...getFieldProps("reset_email")}
                        error={Boolean(touched.reset_email && errors.reset_email)}
                        helperText={touched.reset_email && errors.reset_email}

                    />

                </Box>



                <Box
                    sx={{ mt: 2 }}
                    component={motion.div}
                    initial={animateInitialOut2}
                    animate={ result.response ? animateUpToBottom : animateBottomToUp }
                >

                    <ButtonView
                        sx={{width: '100%'}}
                        label={'send Password reset email'}
                        variant={'contained'}
                        onClick={ ButtonView => {


                            if( !email || touched.reset_email && errors.reset_email )
                                return;

                            const url = ['/api/auth'];
                            url.push('check-user');
                            url.push(email);

                            const request = new AISHTTPRequest();
                            request.beforeSend(()=>{
                                ButtonView.setInProcess(true);
                            }).send( url.join('/') , 'POST', [] )
                                .then( data => {
                                    ButtonView.setInProcess( false );
                                    setResult(data);
                                })
                                .catch( error => {
                                    console.log('Error', error);
                                    setResult(error);
                                    ButtonView.setInProcess( false );
                                })
                        }}
                        onLoad={ ButtonView1 => {

                        }}
                    />
                </Box>

                {/*Try Again Message*/}


                { result.response &&
                <Box
                    component={motion.p}
                    initial={animateInitialOut}
                    animate={ result.response ? animateBottomToUp : animateUpToBottom  }
                >
                    If you don't receive an email please check your spam folder or
                    <ButtonView label={'try again'} variant={'text'} onClick={ event => {
                        setResult({response: null, severity: null, message: null});
                    }} />
                </Box>
                }



            </Box>
        </React.Fragment>


    )

};

export default ForgotPassword;
